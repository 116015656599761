class Soraka {

    constructor(configuracoes = {}){
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // Junta os dados
        this.configuracoes = configuracoes;

        this.time_delay = (this.configuracoes.delay ? this.configuracoes.delay : 2000) * 1000;

        // Valida algumas coisas importantes
        const resposta = this._validate();

        if(!resposta){
            return;
        }

        this.flag = false;
        this.atual = 0;
        this.imagemAnterior = '';
        this.total = Object.keys(this.configuracoes.bananas).length;
        this.setTimeoutVar;

        this.state = {};

        this.start();

        this._listenings();
        
    }

    _validate(){
        
        // Verifica se está sendo informado o elemento aonde vamos renderizar.
        if(!this.configuracoes.element_render){
            console.error('Ops, informe em qual elemento vai ser renderizado.');
            return
        }

        // Verifica se o elemento informado para renderizar é o elemento ou um ID.
        if(typeof(this.configuracoes.element_render) == 'string'){

            if(!document.getElementById(this.configuracoes.element_render)){
                console.error('O elemento render informado por ID, não existe no DOM.');
                return;
            }
        }
        if(typeof(this.configuracoes.element_render) == 'object'){
            if(!this.configuracoes.element_render){
                console.error('O elemento render informado, não existe no DOM.');
                return;
            }
        }

        if(!this.configuracoes.bananas || Object.keys(this.configuracoes.bananas).length <= 0){
            console.error('Ops, não há nenhuma banana configurada.');
            return
        }

       /*  for(let i in this.configuracoes.bananas){
            if(!this.configuracoes.bananas[i].link){
                console.error('Ops, há alguma banana sem LINK.');
                return;
            }
        } */

        if(typeof(soraka_right) == 'undefined'){
            console.error('Ops, não há um botão RIGHT da Soraka no DOM.');
            return
        }
        if(typeof(soraka_left) == 'undefined'){
            console.error('Ops, não há um botão LEFT da Soraka no DOM.');
            return
        }

        return true;
    }

    right(){

        let proxima = this.atual + 1;
        proxima = (proxima >= this.total) ? 0 : proxima;

        this.render(proxima);
    }

    left(){

        let anterior = this.atual - 1;
        anterior = (anterior < 0) ? (this.total - 1) : anterior;

        this.render(anterior, 'soraka_out');
    }

    toggleplay(){
        this.configuracoes.autoplay = !this.configuracoes.autoplay

        if(this.configuracoes.autoplay){
            
            this.right();
        }
    }

    stop(){
        this._sleepStop();
        this.configuracoes.autoplay = false;
    }

    play(){
        this.configuracoes.autoplay = true;
        this.right();
    }
   

    _listenings(){
        soraka_right.addEventListener('click', f => {
            this._sleepStop();
            this.right();
        }, true);

        soraka_left.addEventListener('click', f => {
            this._sleepStop();
            this.left()
        }, true);

        if(soraka_play){
            soraka_play.addEventListener('click', evt => {
                /* let botao = evt.target;
                botao.innerHTML = 'STOP'; */
                this._sleepStop();
                this.play()
            }, true);
        }
        window.addEventListener("resize", function() {
            soraka.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }, true);
    }
    
    async _sleep(time = this.time_delay){
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    _sleepStop(){
        clearTimeout(this.setTimeoutVar);
    }

    async start() {
        
        this._initialState();

        if(this.configuracoes.autoplay){

            this.setTimeoutVar = await this._sleep(this.configuracoes.time_delay);
    
            this.render();
        }
    }

    _initialState(){

        this.state = {
            'anterior': this.configuracoes.bananas.length - 1,
            'atual': 0,
            'proxima': 1
        };

        var divPaiBananas = document.createElement("div");
        divPaiBananas.id = "sorakaPai"
        for(let banana in this.configuracoes.bananas){

            var div = document.createElement("div");
            div.id = 'banana' + banana;

            divPaiBananas.appendChild(div);
        }

        this.configuracoes.element_render.appendChild(divPaiBananas);

        const capa = this.configuracoes.bananas[this.state.atual];
        if(this.width < 1023){
            this.configuracoes.height = 17;
        }
        if(this.width >= 1024 && this.width <= 1366){
            this.configuracoes.height = 55;
        }
        let imagem_bg = this.width > 1024 ? capa.imagem.big : capa.imagem.small;
        if(banana0){
            banana0.style.backgroundImage = `url(${imagem_bg})`;
            banana0.setAttribute('onclick', this._linkSoraka(capa));
            banana0.style.height = this.configuracoes.height ? this.configuracoes.height + 'vh' : '50vh';
        }

        if(soraka_pai){
            soraka_pai.style.height = this.configuracoes.height ? this.configuracoes.height + 'vh' : '50vh';
        }
        divPaiBananas.style.height = this.configuracoes.height ? this.configuracoes.height + 'vh' : '50vh';
        divPaiBananas.classList.add('soraka');
    }

    async render(primeira_vez = true){

        /* PRECISA SER AQUI, NAO MUDAR. inicio */
        if(this.configuracoes.bananas[this.state.atual + 1]){
            this.state.proxima = this.state.atual + 1;
        }
        if(this.state.proxima >= this.configuracoes.bananas.length){
            this.state.proxima = 0;
        }
        if(this.state.anterior < 0){
            this.state.anterior = this.configuracoes.bananas.length - 1;
        }
        /* PRECISA SER AQUI, NAO MUDAR. fiiim */

        // Renderizar as Imagens
        this._showhidden(primeira_vez);
        
        // Aguardar
        this.setTimeoutVar = await this._sleep(this.configuracoes.time_delay);

        // TENTAR setar as proximas.
        this.state.anterior = this.state.proxima - 1;
        this.state.atual = this.state.proxima;
        this.state.proxima = this.state.atual + 1;

        // LOOP
        this.render(primeira_vez = false);
    }

    _melhorImagem(imagem){
        return this.width > 1024 ? imagem.big : imagem.small; 
    }

    _linkSoraka(banana){

        if(banana.link != ''){
            if(banana.interno){
                return `window.location.href = '${banana.link}'`;
            }
          
            return `window.open('${banana.link}')`;
        }

        return '';
    }

    async _showhidden(primeira_vez = true){
        
        // RESETA.. concluir.
        if(this.state.proxima == 0){
            
            //this.configuracoes.element_render.innerHTML = '';
            this.state = {
                'anterior': this.configuracoes.bananas.length - 1,
                'atual': 0,
                'proxima': 1
            };

            var sorakinhas = soraka_pai.querySelectorAll('div');

            for(var i = 0; i < sorakinhas.length; i++){
                sorakinhas[i].classList.remove('soraka_um');                
                sorakinhas[i].style.backgroundImage = "";                
                sorakinhas[i].style.height = "";
            }
        }

        const capa = this.configuracoes.bananas[this.state.proxima];
        let imagem_bg = this._melhorImagem(capa.imagem);
        
        // Identifica quem é anterior, atual e proximo
        var elementAnterior = document.getElementById('banana' + this.state.anterior);
        var elementAtual = document.getElementById('banana' + this.state.atual);
        var elementProxima = document.getElementById('banana' + this.state.proxima);
        
        if(this.state.atual == 0 && primeira_vez == false){
            banana0.style.backgroundImage = `url(${this._melhorImagem(this.configuracoes.bananas[this.state.anterior].imagem)})`;
            banana0.style.height = this.configuracoes.height ? this.configuracoes.height + 'vh' : '40vh';
        }
        


        elementProxima.setAttribute('onclick', this._linkSoraka(capa));
        elementProxima.style.backgroundImage = `url(${imagem_bg})`;
        elementProxima.style.height = this.configuracoes.height ? this.configuracoes.height + 'vh' : '40vh';

        elementProxima.classList.add('soraka_um');

        // Aguardar
        this.setTimeoutVar = await this._sleep(this.configuracoes.time_delay);

        elementProxima.classList.remove('soraka_um');
        
        if(this.state.atual !== 0){
            elementAtual.style.backgroundImage = `url(${this._melhorImagem(this.configuracoes.bananas[this.state.proxima].imagem)})`;
        }
    }
}